/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": ""
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "3" } }, [
            _c("h4", { staticClass: "heading" }, [_vm._v("USPS Delivery")])
          ]),
          _c("b-col", [
            _c("p", [
              _vm._v(
                "for " +
                  _vm._s(_vm.rowData.firstname) +
                  " " +
                  _vm._s(_vm.rowData.lastname)
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticStyle: { height: "100px" } },
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { name: "check-button", switch: "" },
                  model: {
                    value: _vm.receiveUSPS,
                    callback: function($$v) {
                      _vm.receiveUSPS = $$v
                    },
                    expression: "receiveUSPS"
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.receiveUSPSText(_vm.receiveUSPS)) + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            { attrs: { offset: "2", cols: "10" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            block: "",
                            variant: "outline-dark",
                            pill: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v(" Close ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { block: "", variant: "secondary", pill: "" },
                          on: { click: _vm.save }
                        },
                        [_vm._v(" Submit ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }